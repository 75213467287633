import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import MainToolbar from "../components/main-toolbar"
import LineTitle from "../components/LineTitle"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Box from "@material-ui/core/Box"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
    a: {
      color: "#888",
      textDecoration: "none",
      "&:hover": {
        color: "#bbb",
      },
    },
  },
  background: {
    padding: 0,
    backgroundColor: "#202020",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    paddingBottom: theme.spacing(5),
  },
  contactTitle: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "170px",
    ["@media (max-width:780px)"]: {
      paddingTop: "100px",
    },
  },
  contactTitle2: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "40px",
  },
  header: {
    color: "white",
    fontWeight: "bold",
  },
  content: {
    color: "white",
    paddingBottom: "16px",
  },
}))

const Complainment = ({ intl, location }) => {
  const classes = useStyles()
  const form = "https://kooldigital.lpages.co/test-form/"
  const t = (id) => intl.formatMessage({ id })
  const locale = intl.locale

  const complainDataNl = [
    {
      header: "Klachten",
      content:
        "Wij vinden het belangrijk dat onze klanten tevreden zijn en wij doen daartoe ons uiterste best. Ondanks onze aandacht en inzet kan het gebeuren dat u niet tevreden bent. U kunt dan een klacht indienen. U kunt er op rekenen dat wij uw klacht serieus nemen en dat wij alles zullen doen om samen tot een oplossing te komen. Ook helpt uw klacht ons om onze service te verbeteren.",
    },
    {
      header: "Wat kunt u doen?",
      content:
        "Uw klacht kunt u per email sturen naar het secretariaat : info@faceinstitute.nl.  Omschrijft u alstublieft zo precies mogelijk uw klacht en op welke behandeling(en), behandelaar(s), locatie en tijd(en) deze betrekking heeft. Wij nemen dan zo spoedig mogelijk contact met u op en doen ons uiterste best om de klacht naar tevredenheid op te lossen.",
    },
    {
      header: "Klachtenfunctionaris",
      content:
        "Als u er met ons om wat voor reden dan ook niet uit komt dan kunt u zich wenden tot onze externe klachtenfunctionaris. Deze klachtenfunctionaris is onafhankelijk en zal uw klacht met de grootst mogelijke zorg behandelen. Zij zijn te bereiken op klachtenengeschillen@dokh.nl en telefonisch 072-520 8325",
    },
  ]

  const complainData = [
    {
      header: "Complaints",
      content:
        "At our organization, we prioritize customer satisfaction and strive to provide the best possible service. However, despite our efforts, it may happen that you are not completely satisfied. In such cases, you are welcome to file a complaint. Rest assured, we take all complaints seriously and will work together with you to find a suitable solution. Your feedback also helps us to improve our services.",
    },
    {
      header: "What can you do?",
      content:
        "You can submit your complaint via email to our secretariat at: info@faceinstitute.nl. Please describe your complaint as clearly as possible, including details about the treatment(s), practitioner(s), location, and time(s) involved. We will contact you as soon as possible and do our utmost to resolve the issue to your satisfaction.",
    },
    {
      header: "Complaints Officer",
      content:
        "If, for any reason, we are unable to resolve the matter together, you can contact our external complaints officer. This officer is independent and will handle your complaint with the utmost care. They can be reached at klachtenengeschillen@dokh.nl or by phone at 072-520 8325.",
    },
  ]

  const TermsPart = ({ header, content }) => {
    const createMarkup = (text) => {
      return { __html: text.replace(/\n/g, "<br/>") }
    }

    return (
      <div>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography component="legend" className={classes.header}>
            {header}
          </Typography>
          <Typography
            component="legend"
            className={classes.content}
            dangerouslySetInnerHTML={createMarkup(content)}
          ></Typography>
        </Box>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Complaints" path={location.pathname} />
      <MainToolbar className={classes.toolbar} />
      <Container maxWidth={"xl"} className={classes.background}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Container>
              <LineTitle
                header={intl.formatMessage({ id: "complaint.header" })}
                // subheader={intl.formatMessage({ id: "terms.subHeader" })}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.formContainer}>
            <Typography
              component="h5"
              variant="p"
              align="center"
              className={classes.contactTitle}
            ></Typography>
          </Grid>
        </Grid>
        <Container maxWidth={"md"}>
          <div style={{ height: 50 }}></div>
          {locale === "nl"
            ? complainDataNl.map((policy, index) => (
                <TermsPart
                  key={index}
                  header={policy.header}
                  content={policy.content}
                />
              ))
            : complainData.map((policy, index) => (
                <TermsPart
                  key={index}
                  header={policy.header}
                  content={policy.content}
                />
              ))}
          <div style={{ height: 50 }}></div>
        </Container>
      </Container>
    </Layout>
  )
}

export default injectIntl(Complainment)

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
